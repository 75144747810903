$(window).on("load", function() {

    // $(document).on('click', '#buy', function(btn) {
    //     btn.preventDefault();

    //     if(!$(this).hasClass('btn-blue')) {
    //         $(this).removeClass('btn-blue-outline');
    //         $(this).addClass('btn-blue');

    //         $('#rent').removeClass('btn-blue');
    //         $('#rent').addClass('btn-blue-outline');
    //     }
    // });
    
    // $(document).on('click', '#rent', function(btn) {
    //     btn.preventDefault();

    //     if(!$(this).hasClass('btn-blue')) {
    //         $(this).removeClass('btn-blue-outline');
    //         $(this).addClass('btn-blue');

    //         $('#buy').removeClass('btn-blue');
    //         $('#buy').addClass('btn-blue-outline');
    //     }
    // });
    
	//
	// js pour ajouter la div.table-responsive autour de tous les tableaux présents dans le wysiwyg
	//
	$('.wysiwyg table').wrap('<div class="table-responsive"></div>');
});